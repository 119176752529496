@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;

  background-image: url('./images/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  // backdrop-filter: brightness(40%);

  // @media screen and (max-width: 480px) {
  //   background-image: url('./images/mobile-background.jpg');
  //   background-color: black;
  //   background-size: contain;
  //   background-repeat: repeat;
  //   backdrop-filter: none;
  //   // background-position: center center;
  // }
}

.menu-item {
  @apply text-base text-white cursor-pointer;

  &::after {
    content: " ";
    display: block;
    width: 0%;
    height: 2px;
    background: white;
    transition: width 0.3s ease 0s;
  }

  &:hover {
    &::after {
      width: 100%;
    }
  }


  &.selected {
    &::after {
      width: 100%;
    }
  }
}

.main-btn {
  @apply w-full py-2 text-white border-[#4d4d4d] border-2 rounded font-semibold text-center;
  @apply bg-gradient-to-b from-[#00000050] to-black;
  @apply uppercase;
  
  &:hover {
    @apply from-black to-[#00000050] border-black;
  }

  &:disabled {
    @apply border-gray-400 text-gray-400;

    &:hover {
      @apply bg-transparent text-gray-400;
    }
  }
}